<template>
    <Tippy v-bind="tippyOptions"
           :aria-label="tooltipAriaLabel"
           @create="assignTippyButtonRole">
        <Component :is="elementTag"
                   ref="tooltipRef"
                   class="tippy-wrapper">
            <slot />
        </Component>
        <template v-if="slots.content"
                  #content>
            <slot name="content" />
        </template>
    </Tippy>
</template>

<script setup lang="ts">
    import { Tippy, TippyOptions } from 'vue-tippy';
    import { VNode, computed, ref } from 'vue';
    import { assignTippyButtonRole } from '@/js/helpers/tippyManager';

    // Can't use Tippy's interface since it sets defaults.
    interface Props {
        elementTag?: string,
        theme?: TippyOptions['theme'],
        interactive?: TippyOptions['interactive'],
        trigger?: TippyOptions['trigger'],
        placement?: TippyOptions['placement'];
    }

    const props = withDefaults(defineProps<Props>(), {
        elementTag: 'span',
        theme: 'tip-info',
        interactive: true,
        trigger: 'mouseenter',
        placement: 'top',
    });

    const slots = defineSlots<{
        content: VNode[],
    }>();

    const tooltipRef = ref<HTMLElement | null>(null);

    const tooltipAriaLabel = computed(() => {
        const firstChild = tooltipRef.value?.children[0];

        return firstChild?.getAttribute('aria-label') || '';
    });

    const { elementTag, ...tippyOptions } = props;
</script>

<style scoped lang="scss">
.tippy-wrapper {
    width: min-content;
}
</style>
